import data from "../../data/index.json";

export default function Testimonial() {
  return (
    <section className="testimonial--section" id="testimonial">
      <h1>Fiyatlarımız</h1>
      <div class="price-cards">
  <div class="price-card">
    <div class="price-header">
      <h2>Kurumsal</h2>
    </div>
    <div class="price-body">
      <p class="price">1200₺</p>
      <p>Web Sitesi</p>
      <p>Host&Domain&SSL</p>
      <p>Sosyal Medya Yönetimi (1 Ay)</p>
    </div>
  </div>
  
  <div class="price-card">
    <div class="price-header">
      <h2>Gelişmiş Kurumsal</h2>
    </div>
    <div class="price-body">
      <p class="price">2450₺</p>
      <p>Web Sitesi</p>
      <p>Host&Domain&SSL</p>
      <p>Sosyal Medya ve Reklam Yönetimi (2 Ay)</p>
    </div>
  </div>
  
  <div class="price-card">
    <div class="price-header">
      <h2>E-ticaret</h2>
    </div>
    <div class="price-body">
      <p class="price">Fiyat Bilgisi İçin İletişime Geçin</p>
      <p>E-ticaret Alt Yapılı Site</p>
      <p>Host&Domain&SSl</p>
      <p>Sosyal Medya ve Reklam Yönetimi (3 Ay)</p>
      <p>Logo Kartvizit QR Kod Tasarımı</p>
    </div>
  </div>
</div>

    </section>
  );
}
