import data from "../../data/index.json";

export default function MyPortfolio() {
  return (
    <section className="portfolio--section" id="MyPortfolio">
      <div class="container-2">
  <div class="card">
    <div class="box">
      <div class="content">
        <h2>Neta</h2>
        <h3>Erzurum Espor</h3>
        <p>Neta Soft ile çalışan Erzurum Espor büyümeye devam ediyor.</p>
        <a href="https://www.instagram.com/esporerzurum/">İncele</a>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="box">
      <div class="content">
        <h2>Soft</h2>
        <h3>Emlak Konut</h3>
        <p>Yapım Aşamasında</p>
        <a href="#">İncele</a>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="box">
      <div class="content">
        <h2>Neta </h2>
        <h3>Konyalı Beyzade</h3>
        <p>Sosyal Medya Danışmanlık hizmeti alan Konyalı Beyzade Popülerliğini arttırmaya devam ediyor.</p>
        <a href="https://www.instagram.com/konyali_beyzade/">İncele</a>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="box">
      <div class="content">
        <h2>Soft </h2>
        <h3>Düğün Fotoğrafçısı Murat Çaycı</h3>
        <p>Sosyal Medya Danışmanlık hizmeti alan Düğün Fotoğrafçısı Murat Çaycı çalışmalarını takipçilerine sergilemeye  devam ediyor.</p>
        <a href="https://www.instagram.com/muratcayci5/">İncele</a>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="box">
      <div class="content">
        <h2>Neta </h2>
        <h3>Çilek Organizasyon</h3>
        <p>Sosyal Medya Danışmanlık hizmeti alan Çilek Organizasyon müşteri yelpazesini ve marka gücünü arttırmaya devam ediyor.</p>
        <a href="https://www.instagram.com/cilekorganizasyon25/">İncele</a>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="box">
      <div class="content">
        <h2>Neta </h2>
        <h3>N-Kart</h3>
        <p>N-Kart projemiz ile markanızı dijital ortamda güçlendirin.</p>
        <a href="https://www.instagram.com/netasoft/">Yakında</a>
      </div>
    </div>
  </div>
</div>
    </section>
  );
}
