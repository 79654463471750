export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/net.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <h1 className="skills-section--heading">Hakkımızda</h1>
          <p className="hero--section-description">
          Merhaba! Biz Neta Soft, teknoloji dünyasında yeni bir heyecan verici maceraya adım attık. 18.09.2023 tarihinde kurulan şirketimiz, yazılım ve teknoloji alanında özgün ve yaratıcı çözümler sunma vizyonu ile yola çıktı.
          </p>
          <p className="hero--section-description">
          Neta Soft'un başarısı, tutkulu ve yetenekli bir ekibe dayanmaktadır. Genç ve dinamik ekibimiz, yazılım geliştirme, tasarım ve proje yönetimi gibi alanlarda uzmandır. Müşterilerimize en iyi hizmeti sunmak için bir araya geldik.
          </p>
        </div>
      </div>
    </section>
  );
}
